// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import * as SentryBrowser from '@sentry/browser';

import type { ErrorEvent, EventHint } from '@sentry/types';

import supportedBrowsers from 'shared/helpers/supportedBrowsers';

export function maskName(fullName: string) {
  return fullName
    ?.split(' ')
    ?.map(
      (word) => word.substring(0, 2) + '*'.repeat(Math.max(0, word.length - 2))
    )
    ?.join(' ');
}

function sentryStub() {
  /* eslint-disable no-console */
  return {
    // We explicitly want to use the console in development
    // eslint-disable-next-line no-console
    setUser: (...args) => console.info('Sentry.setUser', ...args),
    addBreadcrumb: (...args) => console.info('Sentry.addBreadcrumb', ...args),
    captureException: (...args) =>
      console.error('Sentry.captureException', ...args),
    captureMessage: (...args) =>
      console.error('Sentry.captureMessage', ...args),
    showReportDialog: (...args) =>
      console.error('Sentry.showReportDialog', ...args),
    withScope(scope) {
      scope({
        setExtras: (...args) =>
          console.debug('Sentry.withScope.setExtras', ...args),
        setFingerprint: (...args) =>
          console.debug('Sentry.withScope.Fingerprint', ...args),
      });
    },
  } as unknown as typeof SentryBrowser; // Intentionally leave client partially unimplemented
  /* eslint-enable no-console */
}

function isApp(event: ErrorEvent, app_name: string) {
  return event?.tags?.app === app_name;
}
function errorMatches(
  event: ErrorEvent,
  hint: EventHint,
  field: string,
  re: RegExp
) {
  if (!hint) return false;
  const err = hint.originalException || event;
  return err && err[field] && re.test(err[field]);
}
function nameMatches(event: ErrorEvent, hint: EventHint, re: RegExp) {
  return errorMatches(event, hint, 'name', re);
}
function messageMatches(event: ErrorEvent, hint: EventHint, re: RegExp) {
  return errorMatches(event, hint, 'message', re);
}
function stackMatches(event: ErrorEvent, hint: EventHint, re: RegExp) {
  return errorMatches(event, hint, 'stack', re);
}
function rejectMatches(hint: EventHint, re: RegExp) {
  return re.test(hint?.originalException as string);
}

function initializeSentry(sentrySettings: Window['IH']['sentry']) {
  SentryBrowser.init({
    dsn: sentrySettings.react_dsn,
    release: sentrySettings.release,
    denyUrls: [/js-agent.newrelic.com/],
    replaysSessionSampleRate: sentrySettings.replays_session_sample_rate,
    replaysOnErrorSampleRate: sentrySettings.replays_on_error_sample_rate,
    integrations: [
      SentryBrowser.replayIntegration({
        maskAllText: false,
      }),
    ],
    beforeSend(event, hint) {
      const originalException = hint?.originalException as {
        name: string;
        message: string;
      } | null;

      // https://incrediblehealth.atlassian.net/browse/WEB-5604
      if (
        messageMatches(event, hint, /Could not load "util"/) &&
        event.tags.mechanism === 'onunhandledrejection'
      ) {
        return null;
      }
      // https://incrediblehealth.atlassian.net/browse/WEB-808
      if (
        isApp(event, 'registration') &&
        messageMatches(event, hint, /undefined is not a function/)
      ) {
        return null;
      }
      // https://incrediblehealth.atlassian.net/browse/WEB-2401
      if (
        isApp(event, 'registration') &&
        messageMatches(event, hint, /UET is not defined/)
      ) {
        return null;
      }
      // https://incrediblehealth.atlassian.net/browse/WEB-3027
      if (
        isApp(event, 'phone_call') &&
        stackMatches(event, hint, /onreadystatechange/) &&
        stackMatches(event, hint, /twilio/)
      ) {
        return null;
      }
      // https://incrediblehealth.atlassian.net/browse/WEB-3960
      if (isApp(event, 'manage') || isApp(event, 'phone_call')) {
        const invalidState =
          nameMatches(event, hint, /InvalidStateError/) &&
          messageMatches(event, hint, /Attempt to register/);
        const typeSDP =
          nameMatches(event, hint, /TypeError/) &&
          messageMatches(event, hint, /getSDP/);
        const connectionError =
          nameMatches(event, hint, /ConnectionError/) &&
          messageMatches(
            event,
            hint,
            /A connection error occurred during the call/
          );
        const transportError =
          nameMatches(event, hint, /TransportError/) &&
          messageMatches(event, hint, /No transport available to send/);
        const tokenExpired =
          nameMatches(event, hint, /AccessTokenExpired/) &&
          messageMatches(event, hint, /20104/);
        const tokenInvalid =
          nameMatches(event, hint, /AccessTokenInvalid/) &&
          messageMatches(event, hint, /20101/);

        if (
          invalidState ||
          typeSDP ||
          connectionError ||
          transportError ||
          tokenExpired ||
          tokenInvalid
        ) {
          let message = 'Twilio error (unknown)';
          if (originalException) {
            message = `Twilio error: ${originalException.name} - ${originalException.message}`;
          }
          SentryBrowser.addBreadcrumb({
            category: 'twilio',
            message,
            level: 'error',
          });
          return null;
        }
      }
      // https://incrediblehealth.atlassian.net/browse/WEB-3026
      if (
        messageMatches(event, hint, /Illegal invocation/) &&
        stackMatches(event, hint, /beacon/)
      ) {
        return null;
      }
      // https://incrediblehealth.atlassian.net/browse/WEB-3568
      if (messageMatches(event, hint, /ResizeObserver loop limit exceeded/)) {
        return null;
      }
      // https://incrediblehealth.atlassian.net/browse/WEB-4333
      if (
        messageMatches(
          event,
          hint,
          /ResizeObserver loop completed with undelivered/
        )
      ) {
        return null;
      }
      // https://incrediblehealth.atlassian.net/browse/WEB-4527
      if (stackMatches(event, hint, /doubleclick\.net/)) {
        return null;
      }
      // https://incrediblehealth.atlassian.net/browse/WEB-4527
      const navigator = window?.navigator;
      if (
        navigator?.onLine === false &&
        (messageMatches(event, hint, /^Failed to fetch$/) ||
          messageMatches(
            event,
            hint,
            /^NetworkError when attempting to fetch resource.$/
          ) ||
          messageMatches(event, hint, /^cancelled$/))
      ) {
        const message = originalException
          ? `Network error: ${originalException.name} - ${originalException.message}`
          : 'Network error (unknown)';

        SentryBrowser.addBreadcrumb({
          category: 'network',
          message,
          level: 'error',
          data: {
            originalException,
          },
        });
        return null;
      }
      // User is using an unsupported browser
      if (
        navigator?.userAgent &&
        supportedBrowsers.test(navigator.userAgent) === false
      ) {
        return null;
      }

      // https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062
      if (
        rejectMatches(hint, /Object Not Found Matching Id:/) &&
        hint?.mechanism?.type === 'onunhandledrejection'
      ) {
        return null;
      }

      // https://github.com/getsentry/sentry-javascript/issues/3147
      try {
        if (
          event.exception.values[0].stacktrace.frames[0].filename ===
          `<anonymous>`
        ) {
          return null;
        }
      } catch (e) {} // eslint-disable-line no-empty

      // https://incrediblehealth.atlassian.net/browse/WEB-6189
      if (
        !isApp(event, 'registration') &&
        nameMatches(event, hint, /TypeError/) &&
        messageMatches(event, hint, /Failed to fetch/)
      ) {
        // Attempting to capture module to separate tracking from other requests
        let maybeModule: string;
        try {
          maybeModule = event.exception.values[0].stacktrace.frames[0].module;
        } catch (e) {} // eslint-disable-line no-empty

        // This is the documented method for de-duping Sentry issues
        // https://docs.sentry.io/platforms/javascript/configuration/filtering/#group-errors-with-greater-granularity
        // eslint-disable-next-line no-param-reassign
        event.fingerprint = ['failed-to-fetch-grouping', maybeModule];
      }

      return event;
    },
  });
  SentryBrowser.setTag('app', sentrySettings.app);
  SentryBrowser.setUser({
    ...sentrySettings.user,
    username: maskName(sentrySettings.user.username),
  });

  return SentryBrowser;
}

const Sentry = window?.IH?.sentry?.react_dsn
  ? initializeSentry(window.IH.sentry)
  : sentryStub();

export default Sentry;
